body {
  color: #ffffff;
  background-color: #000000 !important;
}
#root {
  min-height: 100vh;
  min-width: 1200px;
  user-select: none;
}

@active-color: #e2be30;